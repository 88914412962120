import {
  useWindowScroll,
  useRefHistory,
  watchThrottled,
  useWindowSize,
} from "@vueuse/core";

export function useScrollDirection(min: number = 256) {
  const { y: windowYScroll } = useWindowScroll();
  const { history } = useRefHistory(windowYScroll, {
    capacity: 1,
  });

  const direction = ref<"upward" | "downward" | "none">("none");

  watchThrottled(
    windowYScroll,
    (newScroll) => {
      if (
        history.value[1] &&
        history.value[1].snapshot < newScroll &&
        newScroll > min
      ) {
        direction.value = "downward";
      } else {
        direction.value = "upward";
      }
    },
    {
      throttle: 250,
    },
  );

  return direction;
}

export function useBreakpoints() {
  const { width } = useWindowSize();

  const xs = computed(() => width.value >= 360);
  const sm = computed(() => width.value >= 640);
  const md = computed(() => width.value >= 768);
  const lg = computed(() => width.value >= 1024);
  const xl = computed(() => width.value >= 1280);
  const xl2 = computed(() => width.value >= 1536);

  return {
    /**
     * >= 360
     */
    xs,
    /**
     * >= 640
     */
    sm,
    /**
     * >= 768
     */
    md,
    /**
     * >= 1024
     */
    lg,
    /**
     * >= 1280
     */
    xl,
    /**
     * >= 1536
     */
    xl2,
  };
}
